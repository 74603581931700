import { FeatureFlags } from 'modules/featureFlags'
import { analytics, SegmentEvents } from 'modules/segment'

export const trackExperimentBucketed = <T extends keyof FeatureFlags>(
  flag: T,
  variation: FeatureFlags[T]
) => {
  analytics?.track(SegmentEvents.EXPERIMENT_BUCKETED, {
    flag,
    variation,
  })
}
