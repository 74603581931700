import { List, ListItem, Stack, Text } from '@chakra-ui/react'
import { faSparkle } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Trans, t } from '@lingui/macro'

import {
  MAX_CARDS_FREE,
  MAX_CARDS_PLUS,
  MAX_CARDS_PRO,
} from 'modules/ai/generator/hooks/useMaxCards'
import { Currency, FrequencyUnit, ProductsQuery } from 'modules/api'
import {
  FREE_PROPER_NOUN,
  GAMMA_ARTIFACT_PROPER_NOUN,
  GAMMA_ARTIFACT_PROPER_NOUN_PLURAL,
  GAMMA_PROPER_NOUN,
  PLUS_PROPER_NOUN,
  PRO_PROPER_NOUN,
} from 'modules/i18n/properNouns'
import {
  IMAGE_GENERATE_MODELS,
  isImageModelAvailable,
  isImageModelPremium,
} from 'modules/media/apis/imageGenerate'

import {
  BillingCycleDetail,
  BillingCycleKey,
  ProductDetail,
  ProductKey,
} from '../../types'

type ProductDetails = Record<ProductKey, ProductDetail>

const ImageModelsTooltip = ({ premium }: { premium: boolean }) => {
  const models = Object.entries(IMAGE_GENERATE_MODELS).filter(
    ([key]) => premium == isImageModelPremium(key)
  )

  return (
    <Stack spacing="0">
      <Text color="gray.400">
        <Trans comment="This will be followed by a list of image models that Gamma AI supports">
          Currently includes:
        </Trans>
      </Text>
      <List>
        {models.map(([key, info]) => {
          if (!isImageModelAvailable(key)) return null
          return <ListItem key={key}>{info.label()}</ListItem>
        })}
      </List>
    </Stack>
  )
}

export const getProductDetails = (): ProductDetails => {
  return {
    free: {
      name: FREE_PROPER_NOUN,
      description: t`Basic features to help you create simple decks and sites`,
      buttonVariant: 'plain',
      featureHeading: t`Key features`,
      features: [
        {
          label: t`400 AI credits at signup`,
          icon: <FontAwesomeIcon icon={faSparkle} fixedWidth />,
        },
        {
          label: t`Basic AI image generation`,
          infoTip: <ImageModelsTooltip premium={false} />,
        },
        { label: t`Generate up to ${MAX_CARDS_FREE} cards` },
      ],
      includesHeading: t`Free plan includes...`,
      includes: [
        { label: t`Unlimited ${GAMMA_ARTIFACT_PROPER_NOUN_PLURAL} and users` },
        { label: t`Basic import and PPT/PDF export` },
        { label: t`Website builder`, isBeta: true },
      ],
    },
    plus: {
      name: PLUS_PROPER_NOUN,
      description: t`Unlimited AI and custom branding to elevate your workflow`,
      buttonVariant: 'outline',
      titleColor: 'trueblue.600',
      descriptionColor: 'trueblue.700',
      featureHeading: t`Key features`,
      features: [
        {
          label: <Trans>Unlimited AI creation</Trans>,
        },
        { label: t`Remove "Made with ${GAMMA_PROPER_NOUN}" badge` },
        {
          label: <Trans>Generate up to {MAX_CARDS_PLUS} cards</Trans>,
        },
      ],
      includesHeading: t`Includes everything in ${FREE_PROPER_NOUN}, and...`,
      includes: [
        {
          label: <Trans>10,000 AI tokens per generation</Trans>,
        },
        { label: t`Priority support` },
        { label: t`Early access to new features` },
      ],
      dontChurnMessage: t`Don't lose your AI credits.`,
    },
    pro: {
      name: PRO_PROPER_NOUN,
      description: t`Our most powerful AI and customization tools at your fingertips`,
      titleColor: 'orchid.600',
      descriptionColor: 'orchid.700',
      buttonVariant: 'solid',
      borderColor: 'var(--chakra-colors-gradient-blue-to-orange)',
      featureHeading: t`Key features`,
      features: [
        {
          label: <Trans>Unlimited AI creation</Trans>,
        },
        {
          label: <Trans>Premium AI image generation</Trans>,
          infoTip: <ImageModelsTooltip premium={true} />,
        },
        {
          label: <Trans>Advanced AI editing operations</Trans>,
          infoTip: (
            <Trans>
              Use advanced AI models to edit your entire{' '}
              {GAMMA_ARTIFACT_PROPER_NOUN}. Translate, enhance, simplify, and
              more.
            </Trans>
          ),
          flag: 'editDocAI',
        },
        {
          label: <Trans>Generate up to {MAX_CARDS_PRO} cards</Trans>,
        },
      ],
      includesHeading: t`Includes everything in ${PLUS_PROPER_NOUN}, and...`,
      includes: [
        {
          label: <Trans>25,000 AI tokens per generation</Trans>,
        },
        {
          label: t`Custom domains and URLs`,
          infoTip: t`Publish your ${GAMMA_PROPER_NOUN} site to a custom domain`,
        },
        { label: t`Detailed analytics` },
        { label: t`Custom fonts` },
        { label: t`Password protection` },
      ],
      dontChurnMessage: t`Don't lose unlimited AI.`,
    },
  }
}

export const getBillingCycleDetails = (): Record<
  BillingCycleKey,
  BillingCycleDetail
> => {
  return {
    monthly: {
      name: t`Pay monthly`,
    },
    yearly: {
      name: t`Pay yearly`,
    },
  }
}

/**
 * Temporarily hard-coded in products. In future, we should get these from
 * the public products API.
 */
export const PUBLIC_GAMMA_PRODUCTS: ProductsQuery['products'] = [
  {
    __typename: 'Product',
    id: 'plus-product-id',
    key: 'plus',
    prices: [
      {
        __typename: 'ProductPrice',
        id: 'plus-product-price-month',
        price: 1000,
        currency: Currency.Usd,
        frequencyUnit: FrequencyUnit.Month,
        frequencyInterval: 1,
      },
      {
        __typename: 'ProductPrice',
        id: 'plus-product-price-year',
        price: 9600,
        currency: Currency.Usd,
        frequencyUnit: FrequencyUnit.Year,
        frequencyInterval: 1,
      },
    ],
  },
  {
    __typename: 'Product',
    id: 'pro-product-id',
    key: 'pro',
    prices: [
      {
        __typename: 'ProductPrice',
        id: 'pro-product-price-month',
        price: 2000,
        currency: Currency.Usd,
        frequencyUnit: FrequencyUnit.Month,
        frequencyInterval: 1,
      },
      {
        __typename: 'ProductPrice',
        id: 'pro-product-price-year',
        price: 18000,
        currency: Currency.Usd,
        frequencyUnit: FrequencyUnit.Year,
        frequencyInterval: 1,
      },
    ],
  },
]
