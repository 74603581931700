import * as Sentry from '@sentry/nextjs'
import { useEffect, useState } from 'react'

import { ProductPrice, useProductsLazyQuery } from 'modules/api'
import { localStore } from 'utils/storage'
import { USER_SETTINGS_CONSTANTS } from 'utils/userSettingsConstants'

import { BillingCycleKey } from '../../types'
import { getCheckoutRedirectUrl, getProductPrices } from './utils'

type UseUpsellProArgs = {
  workspaceId?: string
  isPublic?: boolean
  canManageWorkspace: boolean
  selectedBillingCycleKey: BillingCycleKey
}

type ProductPrices = Record<BillingCycleKey, ProductPrice | undefined>

class SentryHandledError extends Error {}

export const useUpsellPro = ({
  workspaceId,
  isPublic = false,
  canManageWorkspace,
  selectedBillingCycleKey,
}: UseUpsellProArgs) => {
  const [getProducts] = useProductsLazyQuery()
  const [productPricesPro, setProductPricesPro] =
    useState<ProductPrices | null>()
  const [productPricesPlus, setProductPricesPlus] =
    useState<ProductPrices | null>()

  const selectedProductPrices =
    selectedBillingCycleKey === 'monthly'
      ? { pro: productPricesPro?.monthly, plus: productPricesPlus?.monthly }
      : selectedBillingCycleKey === 'yearly'
      ? { pro: productPricesPro?.yearly, plus: productPricesPlus?.yearly }
      : null

  const checkoutUrls = workspaceId
    ? {
        pro: getCheckoutRedirectUrl(workspaceId, [
          selectedProductPrices?.pro?.id || '',
        ]),
        plus: getCheckoutRedirectUrl(workspaceId, [
          selectedProductPrices?.plus?.id || '',
        ]),
      }
    : null

  // Pro pricing  annual discount
  const annualProPrice = productPricesPro?.yearly?.price || 0
  const monthlyProPrice = productPricesPro?.monthly?.price || 0
  const monthlyProPriceAnnualized = Math.round(monthlyProPrice * 12)
  const annualProDiscountPercentage =
    (monthlyProPriceAnnualized - annualProPrice) / monthlyProPriceAnnualized

  // Plus pricing annual discount
  const annualPlusPrice = productPricesPlus?.yearly?.price || 0
  const monthlyPlusPrice = productPricesPlus?.monthly?.price || 0
  const monthlyPlusPriceAnnualized = Math.round(monthlyPlusPrice * 12)
  const annualPlusDiscountPercentage =
    (monthlyPlusPriceAnnualized - annualPlusPrice) / monthlyPlusPriceAnnualized

  useEffect(() => {
    setProductPricesPro(null)
    let workspaceIdToUse = workspaceId

    if (!workspaceIdToUse) {
      // look in localstorage
      workspaceIdToUse = localStore.getItem(
        USER_SETTINGS_CONSTANTS.currentWorkspaceId
      )
        ? JSON.parse(
            localStore.getItem(
              USER_SETTINGS_CONSTANTS.currentWorkspaceId
            ) as string
          )
        : undefined
    }

    // TODO - If the workspace already has Gamma Pro, we should
    //        get the productPrice object from the subscription
    // https://linear.app/gamma-app/issue/G-4825/add-means-to-retrieve-the-current-workspaces-subscription-details
    getProducts({
      variables: { workspaceId: workspaceIdToUse },
    })
      .then((res) => {
        if (res.error) {
          throw new Error('GraphQL error: ' + JSON.stringify(res.error))
        }
        const products = res.data?.products
        if (!products || !products.length) {
          Sentry.captureException('[getProducts] No products returned', {
            extra: {
              data: res.data,
            },
          })
          throw new SentryHandledError('[getProducts] No products returned')
        }

        const {
          proMonthlyProductPrice,
          proYearlyProductPrice,
          plusMonthlyProductPrice,
          plusYearlyProductPrice,
        } = getProductPrices(products)

        setProductPricesPro({
          monthly: proMonthlyProductPrice,
          yearly: proYearlyProductPrice,
        })

        setProductPricesPlus({
          monthly: plusMonthlyProductPrice,
          yearly: plusYearlyProductPrice,
        })
      })
      .catch((err) => {
        if (err instanceof SentryHandledError) {
          // Already handled
          return
        }
        console.error('[getProducts] err', err)
      })
  }, [workspaceId, getProducts, canManageWorkspace, isPublic])

  return {
    selectedProductPrices,
    annualProDiscountPercentage,
    annualPlusDiscountPercentage,
    checkoutUrls,
    productPricesPlus,
    productPricesPro,
  }
}
